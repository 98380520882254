/* eslint-disable */

<template>
  <div>
    <vx-card class="mb-base" :class="{ 'cursor-drag': draggingCard === true }" :active.sync="draggingCard">
      <vue-droppable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @droppable:dropped="update_input">
        <div class="fourth-sim">
          <div class="fourth-header">/etc/passwd</div>
          <vue-draggable-container>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4 cursor-dragging"></span>1:20:34<span class="mr-4 ml-4"></span
                >a194dab59c9a365012cd2e04e38c3b12
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>1:22:21<span class="mr-4 ml-4"></span>8482ca2b3d37f390dd01a0c0b4b41b45
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>1:23:45<span class="mr-4 ml-4"></span>004857de37a7c3b472b4d325e45aa134
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>1:23:50<span class="mr-4 ml-4"></span>392800a0123aa12423bcbd342edab33
              </p>
            </div>
          </vue-draggable-container>
          <div class="fourth-header-down">/etc/iptables/iptables-save</div>
          <vue-draggable-container>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                12/30/2016<span class="mr-4 ml-4"></span>1:00:00<span class="mr-4 ml-4"></span>383bc3248z82348ca838d82fc0234cc3
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                12/31/2016<span class="mr-4 ml-4"></span>2:00:00<span class="mr-4 ml-4"></span>383bc3248z82348ca838d82fc0234cc3
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>3:00:00<span class="mr-4 ml-4"></span>383bc3248z82348ca838d82fc0234cc3
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>4:00:00<span class="mr-4 ml-4"></span>383bc3248z82348ca838d82fc0234cc3
              </p>
            </div>
          </vue-draggable-container>
          <div class="fourth-header-down">/boot/initrd.img-2.6.31.20-generic</div>
          <vue-draggable-container>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                12/30/2016<span class="mr-4 ml-4"></span>1:30:00<span class="mr-4 ml-4"></span>848cba435ad9832ebc234c234c23ca02
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                12/31/2016<span class="mr-4 ml-4"></span>2:30:00<span class="mr-4 ml-4"></span>848cba435ad9832ebc234c234c23ca02
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/01/2017<span class="mr-4 ml-4"></span>3:30:00<span class="mr-4 ml-4"></span>7813a82384cbaeb45bd12943a9234df3
              </p>
            </div>
            <div class="dropzone draggable-dropzone--occupied">
              <p class="item-self-simulation font-bold cursor-pointer" draggable="true">
                01/02/2017<span class="mr-4 ml-4"></span>4:30:00<span class="mr-4 ml-4"></span>7813a82384cbaeb45bd12943a9234df3
              </p>
            </div>
          </vue-draggable-container>
        </div>
        <div class="drag-answer">
          <h2>First instance of compromise:</h2>
          <vue-draggable-container>
            <div class="dropzone answer-bg" id="input0"></div>
          </vue-draggable-container>
        </div>
      </vue-droppable>
    </vx-card>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      input0: null,

      options: {
        draggable: '.item-self-simulation',
        dropzone: '.dropzone',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-special-173';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    update_input(x) {
      if (x.data.dropzone.id === 'input0') {
        this.input0 = x.data.dragEvent.data.source.innerText;
      } else {
        this.input0 = '';
      }
    },
    markSimulation() {
      let totalScore = 0;
      if (this.input0 === '01/01/20171:22:218482ca2b3d37f390dd01a0c0b4b41b45') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/vuexy/_variables.scss';
//$theme-dark-bg

.moving-card {
  font-weight: bold;
}

.item-self-simulation {
  background-color: $theme-dark-bg;
  padding: 5px;
}

.dropzone {
  background-size: 0.8rem 0.8rem;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 30px;
  border: 2.5px solid white;
}

.currently-dragging-special-173 {
  background-color: #0042ff;
  padding: 10px;
  color: white;
  z-index: 99;
}

.fourth-header {
  margin-bottom: 15px;
  margin-left: 5px;
  color: rgba(var(--vs-primary), 1);
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
}

.fourth-header-down {
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: 5px;
  color: rgba(var(--vs-primary), 1);
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
}

.drag-answer {
  padding: 20px 15px 20px 25px;
}
</style>
